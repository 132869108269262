<template>
<div class="row">
    <div class="col-md-8 offset-md-2">
        <form method="POST" @submit.prevent="accionformulario">

        <span class="h4 titulosg">Asignación Académica</span>
        
        <div class="p-4 mt-3 bg-white mb-2">

            <label for="" class="form-label">Seleccione el empleado:</label>
            <select   class="form-select" required @change="consultarasignaciones" v-model="datosasignacion.personas_idpersonas">
                <option  v-for="item in personal" :key="item.idpersonalcontrato" :value="item.personas.idpersonas">   {{item.personas.nombres}} {{item.personas.apellidos}} - {{ item.cargo.cargo }}  </option>
            </select>
        </div>
         
        <div class="p-4 mt-3 bg-white">

            <label for="" class="form-label">Grado:</label>
            <select   class="form-select mb-2" required v-model="datosasignacion.acagradosgrupos_idacagradosgrupos">
                <option  v-for="item in listagrados" :key="item.idacagradosgrupos" :value="item.idacagradosgrupos">   {{item.nombregradogrupo  }}  </option>
            </select>

            <label for="" class="form-label">Asignatura:</label>
            <select   class="form-select" required v-model="datosasignacion.acaasignaturas_idacaasignaturas">
                <option  v-for="item in listaasignaturas" :key="item.idacaasignaturas" :value="item.idacaasignaturas">   {{item.descripcion}}  </option>
            </select>

            <button class="btn btn-primary mt-2" type="submit">Asignar</button>
        </div>

        <div class="p-4 mt-3 bg-white">
            
            <strong>Asignaciones Académicas </strong> <span class="badge bg-primary"> {{asignacionesactuales.length}} </span>
            <div class="table-responsive">
                <table class="table align-middle mt-2">
                    <thead>
                        <tr>
                            <td>Asignatura</td>
                            <td>Grado</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in asignacionesactuales" :key="index">
                            <td>  {{item.asignaturas.descripcion}} </td>
                            <td> {{item.grados.nombregradogrupo}} </td>
                            <td style="width: 20px;"> 
                                <button type="button" class="btn btn-sm btn-danger me-2" @click="eliminarasignacion(item.idacaasignacionacademica)"> <i class="far fa-trash-alt"></i> </button> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
                
            <div class="alert alert-warning" v-if="asignacionesactuales.length == 0">
                No hay asignaciones 
            </div>
        </div>

    </form>
    </div>
</div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import axios from 'axios'

export default {
setup(props) {

    const personal = ref([])
    const asignacionesactuales = ref([])
    
    const listagrados = ref([])
    const listaasignaturas = ref([])

    const datosasignacion = reactive({
        acagradosgrupos_idacagradosgrupos: '',
        acaasignaturas_idacaasignaturas: '',
        personas_idpersonas: ''
    })

    //FUNCIONES
    
    const consultarpersonal = () => {
        axios.get('/api/personalactivo').then(response => {
            personal.value = response.data
        })
    }
    
    const consultarasignaciones = () => {
        axios.get('/api/asginacionacademicaxpersona/'+datosasignacion.personas_idpersonas).then(response => {
            asignacionesactuales.value = response.data
        })
    }

    const consultargrados = () => {
        axios.get('/api/grados').then(response => {
            listagrados.value = response.data
        })
    }

    const consultarasignaturas = () => {
        axios.get('/api/todaslasasignaturas').then(response => {
            listaasignaturas.value = response.data
        })
    }
    
    const accionformulario = () => {
        axios.post('/api/agregarasignacion',{ data: datosasignacion }).then(response => {
           if(response.data=="ok"){
                swal("Bien!", "Información Ingresada Correctamente!", "success");   
                }else{                        
                swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");         
            }
           consultarasignaciones()
        })
    }


    const eliminarasignacion = (id) => {
        axios.get('/api/eliminarasignacion/'+id).then(response => {
           if(response.data=="ok"){
                swal("Bien!", "Información Ingresada Correctamente!", "success");   
                }else{                        
                swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");         
            }
           consultarasignaciones()
        })
    }

    onMounted(() => {
        consultarpersonal()  
        consultarasignaturas()  
        consultargrados()  
    })

    return {
        personal,
        datosasignacion,
        asignacionesactuales,
        listagrados,
        listaasignaturas,

        //FUNCIONES
        accionformulario,
        consultarasignaciones,
        eliminarasignacion
    }
}
}
</script>

<style>

</style>
